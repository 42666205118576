
export const randomize = () => {

  const chosenValue = Math.random() < 0.5 ? "figurli" : "gableroux";
  const telegram = document.querySelector("[data-telegram]");
  telegram.href = `https://telegram.me/${chosenValue}`;

  [...document.querySelectorAll('source')].forEach(source => {
    const m = source.src.match(/(.*)\$\$(\d+)(.*)/);
    if (m) {
      const idx = 1 + Math.floor(Math.random() * m[2]);
      source.src = `${m[1]}${idx}${m[3]}`;
      source.closest('video').load();
      console.log('=>', source.src);
    }
  });

  [...document.querySelectorAll('img')].forEach(img => {
    const m = img.src.match(/(.*)\$\$(\d+)(.*)/);
    if (m) {
      const idx = 1 + Math.floor(Math.random() * m[2]);
      img.src = `${m[1]}${idx}${m[3]}`;
      console.log('=>', img.src);
    }
  });

  document.querySelector('.header-hero img').src = (
    document.querySelector('.header-hero video source').src.replace('mp4', 'jpg')
  );

};