import "./style.scss";
import Header from "./js/Header";
import InViewport from "./js/InViewport";
import { startWords } from "./js/ThreeWords";
import { randomize } from "./js/randomize";
import { setup_faces } from "./js/we_are";

new Header();
new InViewport();
randomize();
setup_faces();

startWords();

window.onbeforeunload = function () {
  // window.scrollTo(0, 0);
};

const seli1 = document.querySelector("#seli1");
const seli2 = document.querySelector("#seli2");

seli1.onended = () => {
  seli1.pause();
  seli2.style.opacity = 1;
  seli2.play();
};
