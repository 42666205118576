const defaults = {
  inViewPortClass: "-in-viewport",
  moduleSelector: "[data-in-viewport]",
};

export default class InViewport {
  constructor(settings = {}) {
    this.settings = { ...defaults, ...settings };

    const modules = [
      ...document.querySelectorAll(this.settings.moduleSelector),
    ];

    if (modules.length === 0) return this;

    const options = {
      root: null,
      threshold: 0,
    };

    const pageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        entry.target.classList.toggle(this.settings.inViewPortClass);
        observer.unobserve(entry.target);
      });
    }, options);

    modules.forEach((module) => {
      pageObserver.observe(module);
    });

    return this;
  }
}
