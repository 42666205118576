import { TweenMax, TimelineMax } from "gsap";
import ScrollMagic from "./GsapScrollMagic";

export default class Header {
  constructor() {
    this.isTouch = undefined;

    const header = document.querySelector("[data-header-hero]");
    const arrow = document.querySelector('[data-pointing-arrow]')

    const controller = new ScrollMagic.Controller();
    const timeline = new TimelineMax();
    const duration = 1000;

    this.onWindowResize();
    window.addEventListener("resize", () => this.onWindowResize());

    if (this.isTouch) {
      header.querySelector("video.hero").style.display = "none";
      header.classList.add("-is-touch");
    } else {
      // header.querySelector("img.hero").classList.add("absolute");
      header.querySelector("img.hero").style.display = "none";
      const hero = header.querySelector("video.hero");
      timeline.add(
        TweenMax.to("#line1", 1, { transform: "translateY(-5vh)" }),
        "first"
      );

      const scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        duration,
        triggerHook: "onLeave",
        reverse: false,
      })
        .setPin("#pin")
        .setTween(timeline)
        .addTo(controller);

      scene.on("end", () => {
        arrow.classList.add('-hide')
      });

      timeline.eventCallback("onUpdate", () => {
        if (isNaN(hero.duration) || !isFinite(hero.duration)) return;
        hero.currentTime = timeline.progress() * hero.duration;
      });

      timeline.eventCallback("onComplete", () => {
        scene.removePin(true);
        window.scrollBy(0, -duration);
      });
    }
  }
  onWindowResize() {
    this.checkIfTouch();
  }

  checkIfTouch() {
    if ("ontouchstart" in document.documentElement) {
      this.isTouch = true;
    } else {
      this.isTouch = false;
    }
  }
}
