// TODO Andreas: Remove logs in console

export const setup_faces = () => {
  const sticky_start = document.querySelector('.section-about .sticky-start');
  const we_are = document.querySelector("#we_are");
  const we_are_sticky = document.querySelector("#we_are_sticky");

  const sticky = () => getComputedStyle(we_are_sticky).display !== 'none';

  we_are.addEventListener('play', () => {
    we_are.playbackRate = 2;
  });
  
  // non-sticky
  we_are.addEventListener("timeupdate", (e) => {
    const spotlight = Math.floor(e.target.currentTime / 80 * 6);
    ['andreas', 'gab', 'roli'].forEach((who, idx) => {
      const progress = document.querySelector(`#${who} .progressbar`);
      if (sticky()) {
        progress.style.display = 'none';
        return;
      }
      progress.style.display = 'block';
      progress.classList[spotlight === idx * 2 ? 'add' : 'remove']('-increase');
      progress.classList[spotlight !== idx * 2 ? 'add' : 'remove']('-decrease');
    });
  });

  // sticky
  // Without below hack, on mobile "seeking" will pile up and effectively delay
  // any seeking until much later (when scrolling pauses).
  let seeking = false;
  we_are_sticky.addEventListener('seeking', function() {
      seeking = true;
  });
  we_are_sticky.addEventListener('seeked', function() {
      seeking = false;
  });
  let ticking = false;

  let watching = false;
  const andreas = document.getElementById('andreas');
  const gab = document.getElementById('gab');
  const roli = document.getElementById('roli');
  we_are_sticky.currentTime = 0;
  const onscroll = () => {
    const top0 = we_are_sticky.getBoundingClientRect().height;
    const top1 = andreas.getBoundingClientRect().top - top0;
    const top2 = gab.getBoundingClientRect().top - top0;
    const top3 = roli.getBoundingClientRect().top - top0;
    let currentTime = null;
    if (top1 < 0 && top2 > 0) {
      const x = -top1 / (-top1 + top2);
      // console.log('a -> g', x);
      currentTime = 3 * x;
    } else if (top2 < 0 && top3 > 0) {
      const x = -top2 / (-top2 + top3);
      // console.log('g -> r', x);
      currentTime = 3 * (1 + x);
    }
    if (currentTime) {
      if (!ticking) {
        window.requestAnimationFrame(function() {
          if (!seeking) we_are_sticky.currentTime = currentTime;
          ticking = false;
        });
        ticking = true;
      }
    }
  }
  const observer = new IntersectionObserver(() => {
    if (!sticky())  return;
    const top = sticky_start.getBoundingClientRect().top;
    if (top < 0 && !watching) {
      watching = true;
      console.log('start watching');
      window.addEventListener('scroll', onscroll);
    } else if (watching) {
      watching = false;
      console.log('stop watching');
      window.removeEventListener('scroll', onscroll);
    }
  });
  observer.observe(sticky_start);
};
