const words = [];

const tabular = `digital	technology	science	particles	process	research	discrete
but,and,or	and,or,with,amongst	but,and,or,amongst,with	and,or	and,or,through	through,and,or	but,and,or
analog,nature	nature,art	art,nature	waves,nature	play,experiment	experiment,art	continuous,waves`.split('\n').map(line => line.split('\t'))

tabular[0].map((_, colIndex) => tabular.map(row => row[colIndex])).map(ws =>  // transpose
  ws[0].split(',').map(w0 => ws[1].split(',').map(w1 => ws[2].split(',').map(w2 =>
    words.push([w0, w1, w2])
  )))
)

// console.log('words.length', words.length)

function has_edge(a, b) {
	return a.length === b.length && a.map((w, i) => w != b[i]).filter(x => x).length === 1
}

const edges = new Map()
for(let i = 0; i < words.length - 1; i++) {
	for(let j = i + 1; j < words.length; j++) {
  	if (has_edge(words[i], words[j])) {
    	if (!edges.has(i)) edges.set(i, new Set())
    	if (!edges.has(j)) edges.set(j, new Set())
      edges.get(i).add(j)
      edges.get(j).add(i)
    }
  }
}

function randint(n) {
	return Math.floor(Math.random() * n)
}

const words_counts = new Map(), word_counts = new Map()
let mutating, goal

function mutate() {
  let s = mutating.textContent
  if (s === goal) {
    id = setTimeout(nextWord, 2000)
    return
  }
  const d = s.length - goal.length
  const n = Math.min(s.length, goal.length)
  const diffs = Array(n).fill(0).map((_, i) => s[i] === goal[i] ? -1 : i).filter(i => i >= 0)
  let r = Math.random()
  if (diffs.length === 0 || r < Math.abs(d) * 0.1) {
    if (d < 0) {
      s += goal[mutating.textContent.length]
    } else {
      s = s.substr(0, s.length - 1)
    }
  } else {
	  const i = diffs[Math.floor(12345 * r) % diffs.length]
    s = s.substr(0, i) + goal[i] + s.substr(i + 1)
  }
  mutating.textContent = s
  id = setTimeout(mutate, 150)
}

function nextWord() {
  words_counts.set(word_i, 1 + words_counts.get(word_i) || 0)
  words[word_i].map(word => word_counts.set(word, 1 + word_counts.get(word) || 0))
  
  const neighbors = [...(edges.get(word_i) || [])]
  const possibilities = neighbors.map(i => [
  		// 1. don't repeat combinations
  		words_counts.get(i) || 0,
      // 2. don't repeat words
      words[i].map(w => word_counts.get(w) || 0).reduce((a, b) => a+b),
      // 3. random
      Math.random(),
      // keep reference to index
      i,
  ])
  word_i = possibilities.sort((a, b) =>
  		// sort arrays numerically by content
  		a.map((_, i) => a[i] - b[i])
       .reduce((res, v) => res ? res : v, 0)
	)[0][3];

	[left, middle, right].map((target, i) => {
	  // should trigger exactly once.
  	if (target.textContent !== words[word_i][i]) {
    	mutating = target;
      goal = words[word_i][i]
    }
  })
  mutate()
}

let word_i = randint(words.length)
let id = null
let left, middle, right

export const startWords = () => {
  left = document.getElementById('left')
  middle = document.getElementById('middle')
  right = document.getElementById('right')
  left.textContent = words[word_i][0]
  middle.textContent = words[word_i][1]
  right.textContent = words[word_i][2]
  id = setTimeout(nextWord, 2000)
}

export const stopWords = () => {
  if (id) clearTimeout(id)
  id = null
}
